.lh-link { line-height: 2.5; }

.icon-link { line-height: 0; }

.lh-icon { line-height: .5; }

.w0\.5 { width: .5rem; }

.bw05 { border-width: .050rem; }

.bwx-14 { border-width: 14px; }

.w-fit { width: fit-content; }

.accent--hover {
  &:hover {
    color: $accent-hvr;

    span {
      color: $accent-hvr;
    }
  }
}

.bg-accent--hover {
  &:hover {
    background-color: $accent-hvr;
  }
}

.tm-mv0 {
  p:first-child {
    margin-top: 0;
  }
}

.bg-white-70 {
  background-color: rgba(255, 255, 255, .7);
}

@media #{$breakpoint-large} {
  ._pricing_box {
    min-height: 300px;
  }

  ._contact_page {
    margin-top: -15px;
  }
}
