.input-label {
  transition: transform 150ms ease-out, font-size 150ms ease-out;
}
  
.input-focused .input-label {
  transform: translateY(-100%);
  font-size: .75em;
}

.input { 
  font-size: 16px;
  padding: 0 16px;
  padding-top: 24px;
  padding-bottom: 4px;
  line-height: 1.5;
}


@media #{$breakpoint-large} {
  .input {
    font-size: 20px;
  }
}
