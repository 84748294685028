body, * {
  font-family: 'Open Sans', sans-serif;
}
  
body {
  color: $hl-blk;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.message > p {
  line-height: 0;
  margin: 0;
}

.news-post {
  p {
    color: $copy-blk;
    line-height: 1.5;
    font-size: 16px;
  }

  h2 {
    font-size: 23px;
    margin-top: 50px;
    margin-bottom: 25px;
  }
}

@media #{$breakpoint-large} {
  .news-post {
    p {
      font-size: 20px;
    }
  }
}
