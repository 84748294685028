.fx-8  { font-size: 8px; }
.fx-13 { font-size: 13px; }
.fx-14 { font-size: 14px; }
.fx-15 { font-size: 15px; }
.fx-16 { font-size: 16px; }
.fx-17 { font-size: 17px; }
.fx-18 { font-size: 18px; }
.fx-19 { font-size: 19px; }
.fx-20 { font-size: 20px; }
.fx-22 { font-size: 22px; }
.fx-23 { font-size: 23px; }
.fx-24 { font-size: 24px; }
.fx-25 { font-size: 25px; }
.fx-28 { font-size: 28px; }
.fx-35 { font-size: 35px; }

@media #{$breakpoint-large} {
  .fx-13-l { font-size: 13px; }
  .fx-15-l { font-size: 15px; }
  .fx-16-l { font-size: 16px; }
  .fx-17-l { font-size: 17px; }
  .fx-18-l { font-size: 18px; }
  .fx-20-l { font-size: 20px; }
  .fx-23-l { font-size: 23px; }
  .fx-28-l { font-size: 28px; }
}

@media #{$breakpoint-xlarge} {
  .fx-20-x { font-size: 20px; }
  .fx-22-x { font-size: 22px; }
  .fx-30-x { font-size: 30px; }
}
