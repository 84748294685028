.h-special {
  display: flex;
  
  span:nth-child(1):before {
    content: '#';
    color: $accent;
    margin-right: $spacing-small;
  }
}

.logo-text{
  font-size: 1.55rem;
  padding-bottom: 2px;
}

.logo {
  margin-right: 10px;
  height: 28px;
  width: 28px;
}

.fade-text {
  height: 215px;
}

.fade-text:before {
  content:'';
  width: 100%;
  height: 100%;    
  position: absolute;
  left: 0;
  top: 0;
  background-image: linear-gradient(to bottom, rgba(249,249,249,0) 50%, rgba(249,249,249,1) 100%);
}

@media #{$breakpoint-not-small} {
  .logo {
    margin-right: 13px;
    height: 45px;
    width: 45px;
  }
}

@media #{$breakpoint-large} {
  .fade-text:before {
    background-image: linear-gradient(to bottom, rgba(255,255,255,0) 50%, rgba(255,255,255,1) 100%);
  }

  .logo-text {
    font-size: 2rem;
  }
}
