$slide-height: 520px;
$text-color: #333;
$text-color-light: lighten($text-color, 20%);
$x-large: 20px;
$border-color: #e1e8ed;

@keyframes fadeInRight {
  from {
    opacity: 0;
    transform: translate3d(100%, 0, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.fadeInRight {
  animation-name: fadeInRight;
}

@keyframes fadeOutLeft {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(-100%, 0, 0);
  }
}

.fadeOutLeft {
  animation-name: fadeOutLeft;
}

@keyframes slideOutLeft {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    transform: translate3d(-100%, 0, 0);
  }
}

.slideOutLeft {
  animation-name: slideOutLeft;
}

.animated {
  animation-duration: 1.25s;
  animation-fill-mode: both;
}

.slide {
  &__heading {
    font-weight: 600;
  }
  &__text {
    color: $text-color-light;
  }
  &__link {
    color: $copy-blk;
  }
}

.slider {
  overflow: hidden;
  text-align: center;
  padding-top: 24px;
  &__slides {
    position: relative;
    height: $slide-height;
  }
  &__link {
    display: inline-block;
    font-size: $x-large;
    padding: 10px;
    margin: 0 5px;
    color: lightgrey;
  }
}

.slide {
  position: absolute;
  height: $slide-height;
  width: 100%;
  top: 0;
  left: 0;
  &__heading {
    font-size: $x-large;
    line-height: 1.6;
  }
  &__image {
    margin-bottom: 15px;
  }
  &__text {
    line-height: 1.6;
  }
  &--initial {
    display: none;
  }
}

@media screen and (max-width: 660px) {
  .slider {
    &__slides {
      height: 450px;
    }
  }
  .slide {
    height: 450px;
  }
}

@media screen and (max-width: 515px) {
  .slider {
    &__slides {
      height: 380px;
    }
  }
  .slide {
    height: 380px;
  }
}

@media screen and (max-width: 430px) {
  .slider {
    &__slides {
      height: 340px;
    }
  }
  .slide {
    height: 340px;
  }
}
