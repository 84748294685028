/*

   WHITE SPACE

   Media Query Extensions:
     -ns = not-small
     -m  = medium
     -l  = large

*/


.ws-normal { white-space: normal; }
.nowrap { white-space: nowrap; }
.pre { white-space: pre; }
.prew { white-space: pre-wrap; }

@media (--breakpoint-not-small) {
  .ws-normal-ns { white-space: normal; }
  .nowrap-ns { white-space: nowrap; }
  .pre-ns { white-space: pre; }
}

@media (--breakpoint-medium) {
  .ws-normal-m { white-space: normal; }
  .nowrap-m { white-space: nowrap; }
  .pre-m { white-space: pre; }
}

@media (--breakpoint-large) {
  .ws-normal-l { white-space: normal; }
  .nowrap-l { white-space: nowrap; }
  .pre-l { white-space: pre; }
}

