.nav-item {
  font-size: 1.05rem;
  margin-right: 2.25rem;
}

.subnav-item {
  margin: 1.2rem 0.9rem;
}

/* header navigation dropdown menu */
.nav-dropdown {
  width: intrinsic;
  width: -moz-max-content;
  width: -webkit-max-content;
  width: max-content;
  margin-left: -20px;
  margin-top: 16px;
}

.nav-dropdown::before {
  border-bottom: 10px solid $grey;
  border-left: 11px solid transparent;
  border-right: 11px solid transparent;
  content: "";
  display: inline-block;
  left: 10px;
  position: absolute;
  top: 7px;
}

.nav-dropdown::after {
  border-bottom: 8px solid #FFFFFF;
  border-left: 9px solid transparent;
  border-right: 9px solid transparent;
  content: "";
  display: inline-block;
  left: 12px;
  position: absolute;
  top: 9px;
}

.contact-person {
  width: 80px;
  height: 80px;
}

.nav-cover {
  height: 0;
  background-color: #000;
  opacity: 0;
  transition: opacity 0.250s ease-in;
}

.nav-cover--active {
  height: auto;
  visibility: 1;
  opacity: 0.4;
}

.modal {
  display: none;
  background-color: rgba(0,0,0,0.4);
}

.modal-content {
  margin-top: 0px;
  -webkit-animation-name: animatetop;
  -webkit-animation-duration: 0.4s;
  animation-name: animatetop;
  animation-duration: 0.4s;
}

@-webkit-keyframes animatetop {
  from {top:-300px; opacity:0} 
  to {top:0; opacity:1}
}

@keyframes animatetop {
  from {top:-300px; opacity:0}
  to {top:0; opacity:1}
}

// accordion
.open {
  display: none;
}
  
.accordion-active {
  .closed {
    display: none;
  }
  
  .open {
    display: block;
  }
}
  
.accordion-panel {
  max-height: 0;
  transition: max-height 0.2s ease-out;
}  

// sidemenu (not used)
.sidemenu {
  width: 0;
  transition: 0.5s;
}

.main {
  transition: all .5s;
}

.tooltip::before {
  border-bottom: 10px solid $grey;
  border-left: 11px solid transparent;
  border-right: 11px solid transparent;
  content: "";
  display: inline-block;
  left: 38px;
  position: absolute;
  top: -10px;
}

.tooltip::after {
  border-bottom: 8px solid #FFFFFF;
  border-left: 9px solid transparent;
  border-right: 9px solid transparent;
  content: "";
  display: inline-block;
  left: 40px;
  position: absolute;
  top: -8px;
}

.offer-tooltip {
  opacity: 0;
  transition: opacity 1s ease-in-out;
  left: calc(50% - 100px);

  &:after {
    border-top: 10px solid $dark-red;
    border-left: 11px solid transparent;
    border-right: 11px solid transparent;
    content: "";
    display: inline-block;
    left: calc(50% - 12px);
    position: absolute;
  }
}

.hamburger {
  outline: none;
  padding: 0;
  color: #212121;

  .hamburger-box {
    width: 20px;
    height: 20px;
  }

  .hamburger-inner,
  .hamburger-inner:after,
  .hamburger-inner:before {
    width: 20px;
    border-radius: 0;
    height: 3px;
  }
}

.hamburger--collapse .hamburger-inner:after {
  top: -16px;
}

.hamburger-inner:before {
  top: -8px;
}

@media #{$breakpoint-large} {
  .mwr-67\.5-l { max-width: 67.5rem; }
  .mwr-75-l {    max-width: 75rem; }
  
  .modal-content {
    margin-top: 45px;
  }

  .contact-person {
    width: 150px;
    height: 150px;
  }  
}
