.pvr-1\.25 { padding: 1.25rem 0; }

.pax-17\.5 { padding: 17.5px; }

.phr-1\.2 { padding-left: 1.2rem; padding-right: 1.2rem; }
.phx-15 { padding-left: 15px; padding-right: 15px; }
.phx-22 { padding-left: 22px; padding-right: 22px; }
.phx-20 { padding-left: 20px; padding-right: 20px; }

.pvx-6 {  padding-top: 6px; padding-bottom: 6px; }
.pvx-14 { padding-top: 14px; padding-bottom: 14px; }
.pvx-18 { padding-top: 18px; padding-bottom: 18px; }
.pvx-35 { padding: 35px 0; }
.pvx-44 { padding: 44px 0; }
.pvx-25 { padding-top: 25px; padding-bottom: 25px; }
.pvx-32 { padding-top: 32px; padding-bottom: 32px; }
.pvx-55 { padding-top: 55px; padding-bottom: 55px; }
.pvx-80 { padding: 80px 0; }

.ptx-3 { padding-top: 3px; }
.ptx-6 { padding-top: 6px; }
.ptx-10 { padding-top: 10px; }
.ptx-11 { padding-top: 11px; }
.ptx-16 { padding-top: 16px; }
.ptx-25 { padding-top: 20px; }
.ptx-25 { padding-top: 25px; }
.ptx-26 { padding-top: 26px; }
.ptx-50 { padding-top: 50px; }
.ptx-60 { padding-top: 60px; }

.pbx-20 { padding-bottom: 20px; }
.pbx-25 { padding-bottom: 25px; }
.pbx-50 { padding-bottom: 50px; }
.pbx-60 { padding-bottom: 60px; }
.pbx-80 { padding-bottom: 80px; }

.mtx-14 { margin-top: 14px; }
.mbx-64 { margin-bottom: 64px; }
.mrx-10 { margin-right: 10px; }

.mbx-20 { margin-bottom: 20px; }
.mbx-25 { margin-bottom: 25px; }
.mbx-50 { margin-bottom: 50px; }
.mbr-4 { margin-bottom: 4rem; }
.mbr-3 { margin-bottom: 3rem; }

.mvr-3\.2 { margin-top: 3.2rem; margin-bottom: 3.2rem; }
.mhx-20 { margin-left: 20px; margin-right: 20px; }
.mvx-20 { margin-top: 20px; margin-bottom: 20px; }
.plx-20 { padding-left: 20px; }

@media #{$breakpoint-not-small} {
  .phx-0-ns { padding-left: 0; padding-right: 0; }
  .pbr-0\.5-ns { padding-bottom: 0.125rem; }
  .ptx-32-ns { padding-top: 32px; }
  .ptx-45-ns { padding-top: 45px; }
  .ptx-90-ns { padding-top: 90px; }

  .pbx-14-ns { padding-bottom: 14px; }
  .pbx-60-ns { padding-bottom: 60px; }
  .pbx-65-ns { padding-bottom: 65px; }
  .pbx-70-ns { padding-bottom: 70px; }
  .pbx-80-ns { padding-bottom: 80px; }
  .pbx-90-ns { padding-bottom: 90px; }

  .mrx-35-ns { margin-right: 35px; }
  .mbx-50-ns { margin-bottom: 50px; }
}

@media #{$breakpoint-medium} {
  .ptx-6-m { padding-top: 6px; }
  .ptx-16-m { padding-top: 16px; }
  .ptx-50-m { padding-top: 50px; }

  .pbx-14-m { padding-bottom: 14px; }
  .pbx-25-m { padding-bottom: 25px; }
  .pbx-60-m { padding-bottom: 60px; }

  .ptx-0-m { padding-top: 0; }
  .pbx-0-m { padding-bottom: 0; }
  .phx-0-m { padding-left: 0; padding-right: 0; }
  .pvx-32-m { padding-top: 32px; padding-bottom: 32px; }

  .pvx-55-m { padding-top: 55px; padding-bottom: 55px; }
  .pvx-80-m { padding: 80px 0; }

  .mvx-20-m { margin-top: 20px; margin-bottom: 20px; }
  .mvr-5-m { margin-top: 5rem; margin-bottom: 5rem; }

  .mbx-0-m { margin-bottom: 0px; }
  .mbx-50-m { margin-bottom: 50px; }
  .mbx-80-m { margin-bottom: 80px; }
  .mbr-4-m { margin-bottom: 4rem; }
  .mbr-3-m { margin-bottom: 3rem; }
}

@media #{$breakpoint-large} {
  .ptx-6-l { padding-top: 6px; }
  .ptx-16-l { padding-top: 16px; }
  .ptx-50-l { padding-top: 50px; }
  .ptx-70-l { padding-top: 70px; }

  .pbx-14-l { padding-bottom: 14px; }
  .pbx-25-l { padding-bottom: 25px; }
  .pbx-40-l { padding-bottom: 40px; }
  .pbx-45-l { padding-bottom: 45px; }
  .pbx-60-l { padding-bottom: 60px; }

  .ptx-0-l { padding-top: 0; }
  .pbx-0-l { padding-bottom: 0; }
  .phx-0-l { padding-left: 0; padding-right: 0; }
  .phx-25-l { padding-left: 25px; padding-right: 25px; }
  .pvx-32-l { padding-top: 32px; padding-bottom: 32px; }

  .pvx-55-l { padding-top: 55px; padding-bottom: 55px; }
  .pvx-80-l { padding: 80px 0; }

  .mvx-20-l { margin-top: 20px; margin-bottom: 20px; }
  .mvx-40-l { margin-top: 40px; margin-bottom: 40px; }

  .mhx-25-l { margin-left: 25px; margin-right: 25px; }

  .mvr-5-l { margin-top: 5rem; margin-bottom: 5rem; }

  .mlx-25-l { margin-left: 25px; }
  .mrx-25-l { margin-right: 25px; }

  .mbx-0-l { margin-bottom: 0px; }
  .mbx-25-l { margin-bottom: 25px; }
  .mbx-50-l { margin-bottom: 50px; }
  .mbx-80-l { margin-bottom: 80px; }
  .mbr-4-l { margin-bottom: 4rem; }
  .mbr-3-l { margin-bottom: 3rem; }
}

@media #{$breakpoint-xlarge} {
  .ptx-16-x { padding-top: 16px; }
  .ptx-90-x { padding-top: 90px; }
  .pbx-65-x { padding-bottom: 65px; }

  .phx-0-x { padding-left: 0; padding-right: 0; }
  .plx-25-x { padding-left: 25px; }
  .pbx-80-x { padding-bottom: 80px; }
  .mvr-5-x { margin-top: 5rem; margin-bottom: 5rem; }
  .mlx-0-x { margin-left: 0; }
  .mrx-0-x { margin-right: 0; }
  .mlx-25-x { margin-left: 25px; }
  .mrx-25-x { margin-right: 25px; }
  .mhx-25-x { margin-left: 25px; margin-right: 25px; }
  .mhx-32-x { margin-left: 64px; margin-right: 64px; }
  .mhx-64-x { margin-left: 64px; margin-right: 64px; }
}
