.mood {
  height: 590px;
}

.mood-heading {
  padding: 1.2rem 1.25rem;
  br { display: none; }
}

.mood-heading-about,
.mood-heading-start {
  padding: 2.2rem 1.25rem;
}

.mood-home {
  background-image: url('/assets/images/home-mobile.jpg');
}

.mood-online-shop {
  background-image: url('/assets/images/online-shop-mobile.jpg');
}

.mood-warenwirtschaft {
  background-image: url('/assets/images/wawi-mobile.jpg');
}

.mood-start {
  background-image: url('/assets/images/start-mobile.jpg');
}

.mood-direktvermarktung {
  background-image: url('/assets/images/vorteile-der-direktvermarktung-mobile.jpg');
}

.mood-about {
  background-image: url('/assets/images/trademate-gruender-mobile.jpg');
}

.mood-not-found {
  background: url('/assets/images/404.svg') no-repeat center 0 fixed;
}

@media #{$breakpoint-large} {
  .mood {
    height: 48vh;
  }
  
  .mood-heading {
    padding: 1.5rem 1.8rem;

    br { display: inline; }
  }  

  .mood-home {
    background-image: url('/assets/images/home-desktop.jpg');
  }

  .mood-online-shop {
    background-image: url('/assets/images/online-shop-desktop.jpg');
  }

  .mood-warenwirtschaft {
    background-image: url('/assets/images/wawi-desktop.jpg');
  }

  .mood-start {
    background-image: url('/assets/images/start.jpg');
  }  

  .mood-direktvermarktung {
    background-image: url('/assets/images/vorteile-der-direktvermarktung-desktop.jpg');
  }
  
  .mood-about {
    height: 51vh;
    background-image: url('/assets/images/trademate-gruender.jpg');
  }  
}

@media #{$breakpoint-xlarge} {
  .mood, .mood-about {
    height: 72vh;
  }
}
